import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MaterialOrderContext from "../../../../../context/materialOrder/materialOrderContext";
import ItemContext from "../../../../../context/item/itemContext";
import VendorContext from "../../../../../context/vendor/vendorContext";
import ShopContext from "../../../../../context/shop/shopContext";
import { useForm, Controller } from "react-hook-form";
import { Modal, Spin } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./material_pdf/Invoice";
import ItemArray from "./MaterialItemArray";
import ReactSelect from "react-select";
import MaterialOrderConfirmedModal from "./MaterialOrderConfirmModal";
import { Row, Col, Form, Input, Button } from "antd";

import { DatePicker } from "antd";
import moment from "moment";

const defaultValues = {
  purchaseOrderNumber: "",
  freight: 0,
  items: [
    {
      item: {
        _id: "",
        name: ""
      },
      quantity: 0,
      newPrice: 0,
      newFreight: 0,
      total: 0,
      description: "",
    },
  ],
};

const MaterialOrderForm = () => {
  const navigate = useNavigate();

  const vendorContext = useContext(VendorContext);
  const { getVendors, vendors, clearVendors } = vendorContext;

  const materialOrderContext = useContext(MaterialOrderContext);
  const {
    updateMaterialOrder,
    currentMaterialOrder,
    setCurrentMaterialOrder,
    addMaterialOrder,
    clearCurrentMaterialOrder,
    getMaterialOrders,
  } = materialOrderContext;

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const [ready, setReady] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isItemsLoading, setIsItemsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  const initialValues = currentMaterialOrder
    ? {
        ...currentMaterialOrder,
        orderedDate: currentMaterialOrder.orderedDate ? moment(currentMaterialOrder.orderedDate) : null,
      }
    : defaultValues;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues: initialValues,
  });

  // Modify cleanup effect to clear both items and vendors
  useEffect(() => {
    return () => {
      clearItems();
      clearVendors();
    };
  }, []);

  // Add effect to clear vendors when component mounts
  useEffect(() => {
    clearVendors();
  }, []);

  // Modify the initial data loading effect
  useEffect(() => {
    const loadInitialData = async () => {
      setIsInitialLoading(true);
      try {
        // Load shop and vendors first
        await Promise.all([
          getShop(),
          getVendors()
        ]);
        setIsInitialLoading(false);

        // Then load items
        setIsItemsLoading(true);
        await getItems();
        setIsDataReady(true);
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setIsItemsLoading(false);
      }
    };

    loadInitialData();
  }, []);

  // Handle form initialization after data is loaded
  useEffect(() => {
    if (isDataReady && currentMaterialOrder?.vendor && currentShop && vendors?.length > 0) {
      // Set vendor options
      const vendorOptions = vendors.map((option) => ({
        label: option.name,
        value: option
      }));

      // Find and set the current vendor
      const currentVendor = vendorOptions.find(
        option => option.value._id === currentMaterialOrder.vendor._id
      );
      if (currentVendor) {
        setValue('vendor', currentVendor.value);
      }

      // Set item options using the current vendor
      const fullVendor = vendors.find(v => v._id === currentMaterialOrder.vendor._id);
      if (fullVendor) {
        const defaultItems = fullVendor.defaultItems || [];
        const newItemOptions = defaultItems.map((item) => {
          const shopValueForCurrentShop = item.shopValues?.find?.(
            (shopValue) => shopValue?.shop?.toString() === currentShop._id?.toString()
          );

          return {
            label: shopValueForCurrentShop?.name || item.name,
            value: item
            // value: {
            //   _id: item._id,
            //   name: shopValueForCurrentShop?.name || item.name,
            //   item: {
            //     name: shopValueForCurrentShop?.name || item.name,
            //     _id: item._id
            //   }
            // }
          };
        });

        setItemOptions(newItemOptions);
      }
    }
  }, [isDataReady, currentMaterialOrder, currentShop, vendors]);

  const vendorOptions =
    vendors &&
    vendors.map((option) => {
      return { label: `${option.name}`, value: option };
    });

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    getMaterialOrders();
    navigate(-1);
  };

  const onSubmit = (order) => {
    if (!currentMaterialOrder) {
      Modal.confirm({
        title: "Create Order?",
        okText: "Yes",
        okType: "success",
        onOk: async () => {
          await setCurrentMaterialOrder(order);
          await addMaterialOrder(order);
          setTimeout(() => {
            handleShow();
          }, 500);
        },
      });
    } else {
      updateMaterialOrder(order);
      handleShow();
    }
  };

  const [itemOptions, setItemOptions] = useState([]);

  const setVendorChange = (e) => {
    console.log('Vendor selected:', e.value);
    // Set the vendor value
    setValue(`vendor`, e.value);

    // Get the default items for the selected vendor
    const defaultItems = e.value?.defaultItems || [];
    console.log('Default items:', defaultItems);

    // Map the vendor's defaultItems to itemOptions
    const newItemOptions = defaultItems.map((item) => {
      console.log('Processing item:', item);
      const shopValueForCurrentShop = item.shopValues?.find?.(
        (shopValue) => shopValue?.shop?.toString() === currentShop?._id?.toString()
      );
      console.log('Shop value found:', shopValueForCurrentShop);

      const option = {
        label: shopValueForCurrentShop?.name || item.name,
        value: item
        // value: {
        //   _id: item._id,
        //   name: shopValueForCurrentShop?.name || item.name,
        //   item: {
        //     name: shopValueForCurrentShop?.name || item.name,
        //     _id: item._id
        //   }
        // }
      };
      console.log('Created option:', option);
      return option;
    });

    console.log('Final item options:', newItemOptions);
    setItemOptions(newItemOptions);
  };

  const setOrderFreight = (value) => {
    setValue(`freight`, value);
  };

  const setExit = () => {
    navigate(-1);
  };

  const dateFormat = "MM/DD/YYYY";

  return (
    <div className="container-fluid" style={{ padding: "2rem" }}>
      <MaterialOrderConfirmedModal handleClose={handleClose} handleShow={handleShow} show={show} />
      
      {isInitialLoading ? (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '50vh' 
        }}>
          <Spin size="large" tip="Loading form data..." />
        </div>
      ) : (
        <>
          <Row justify="end" className="mb-4">
            <Col>
              <Button type="primary" danger onClick={setExit}>
                Back
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form
                className="bg-white shadow-lg rounded-lg"
                style={{ 
                  maxWidth: "95%", 
                  margin: "0 auto",
                  marginBottom: "2rem",
                  padding: "2rem 3rem"
                }}
                onSubmitCapture={handleSubmit(onSubmit)}
                layout="vertical"
              >
                <h4 className="text-center mb-6 text-xl font-semibold">Material Purchase Order Form</h4>

                <div style={{ padding: "0 1rem" }}>
                  <Row gutter={24} align="middle" className="mb-4">
                    <Col span={6}>
                      <Form.Item label="Vendor" required>
                        <Controller
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              options={vendorOptions}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              value={vendorOptions?.find(option => option.value._id === field.value?._id) || null}
                              onChange={(e) => setVendorChange(e)}
                              className="w-full"
                            />
                          )}
                          name="vendor"
                          control={control}
                          rules={{ required: 'Please select a vendor' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="PO#" required>
                        <Controller
                          name="purchaseOrderNumber"
                          control={control}
                          rules={{ required: 'Purchase order number is required' }}
                          render={({ field }) => (
                            <Input {...field} />
                          )}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Order Date">
                        <Controller
                          name="orderedDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker 
                              {...field}
                              format="MM/DD/YYYY"
                              className="w-full"
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item>
                        <Button 
                          type="primary" 
                          htmlType="submit"
                          className="w-full"
                          size="large"
                          style={{ 
                            marginTop: "29px",
                            height: "40px",
                            fontSize: "16px",
                            width: "120%",
                            position: "relative",
                            left: "-20%"
                          }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16} className="mt-4">
                    <Col span={18} />
                    <Col span={3}>
                      <Form.Item label="Freight">
                        <Controller
                          name="freight"
                          control={control}
                          render={({ field }) => (
                            <Input
                              type="number"
                              {...field}
                              onChange={(e) => setOrderFreight(e.target.value)}
                              min={0}
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label="Total">
                        <Controller
                          name="grandTotal"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              readOnly
                              type="number"
                            />
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {isItemsLoading ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <Spin size="small" />
                      <div style={{ marginTop: '8px' }}>Loading items...</div>
                    </div>
                  ) : (
                    <ItemArray
                      control={control}
                      watch={watch}
                      register={register}
                      defaultValues={defaultValues}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      itemOptions={itemOptions}
                      currentShop={currentShop}
                      currentMaterialOrder={currentMaterialOrder}
                    />
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default MaterialOrderForm;

