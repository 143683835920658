import React, { useContext } from "react";
import { Modal, Button, Space } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./material_pdf/Invoice";
import MaterialOrderContext from "../../../../../context/materialOrder/materialOrderContext";
import ShopContext from "../../../../../context/shop/shopContext";
import styled from "styled-components";

const StyledPDFLink = styled.div`
  .pdf-download-link {
    color: #fff;
    text-decoration: none;

    &:hover, &:focus {
      color: #fff;
    }
  }
`;

const MaterialOrderConfirmedModal = ({ handleClose, show }) => {
  const materialOrderContext = useContext(MaterialOrderContext);
  const { currentMaterialOrder } = materialOrderContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const handleDownloadClick = () => {
    // Close modal after a short delay to ensure download starts
    console.log("handleDownloadClick");
    handleClose();
  };

  return (
    <Modal
      title="Download Purchase Order"
      open={show}
      onCancel={handleClose}
      footer={null}
      centered
      width={500}
    >
      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        <p style={{ marginBottom: '24px' }}>
          Your purchase order is ready. Would you like to download it now?
        </p>
        <Space size="middle">
          <StyledPDFLink>
            <Button 
              type="primary"
              onClick={handleDownloadClick}
            >
              <PDFDownloadLink
                document={
                  <Invoice 
                    data={currentMaterialOrder} 
                    currentShop={currentShop} 
                  />
                }
                fileName={`${currentMaterialOrder?.purchaseOrderNumber}_material_order.pdf`}
                className="pdf-download-link"
              >
                {({ loading }) => 
                  loading ? "Preparing document..." : "Download PDF"
                }
              </PDFDownloadLink>
            </Button>
          </StyledPDFLink>
          <Button onClick={handleClose}>
            Cancel
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default MaterialOrderConfirmedModal;
